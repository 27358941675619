@import-normalize html,
body {
	height: 100%;
}

#root {
	height: 100%;
	display: flex;
	flex-direction: column;
}
